import axios from "axios";
import {APIGW_URL} from "@/data/visit-history/shared";

/**
 * Fetch visit records for the given patient and visit status.
 * @param {string} studyId - The patient study ID.
 * @param {string} token - The access token for authorization.
 * @param {number} visitStatus - The visit status (0 or 1).
 * @returns {Promise<Array>} Array of visit records.
 */
async function fetchVisitRecordsByStatus(studyId, token, visitStatus) {
    const apiUrl_visits = `${APIGW_URL}/visit?study_id[eq]=${studyId}&visit_status[eq]=${visitStatus}&visit_reason[eq]=1`;

    try {
        const response = await axios.get(apiUrl_visits, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching visit records:", error);
        throw error;
    }
}

/**
 * Filter visits by date range.
 * This function filters the list of visit records based on a specified start and/or end date.
 * Only visits that fall within the given date range are included in the returned list.
 *
 * @param {Array} visits - The list of visit records to filter. Each visit must include a `date` property.
 * @param {string|null} startDate - The start date for filtering visits (inclusive). Format: "YYYY-MM-DD".
 * @param {string|null} endDate - The end date for filtering visits (inclusive). Format: "YYYY-MM-DD".
 * @returns {Array} Filtered list of visit records that fall within the specified date range.
 */
function filterVisitsByDateRange(visits, startDate, endDate) {
    // If neither startDate nor endDate is provided, return the original list of visits unfiltered.
    if (!startDate && !endDate) {
        return visits;
    }
    // Filter the visits array to include only records within the specified date range.
    return visits.filter((visit) => {
        // Convert the visit date (string) to a Date object for comparison.
        const visitDate = new Date(visit.date);
        // Check if the visit date is on or after the startDate.
        // If startDate is not provided, this condition is always true.
        const isAfterStartDate = startDate ? visitDate >= new Date(startDate) : true;
        // Check if the visit date is on or before the endDate.
        // If endDate is not provided, this condition is always true.
        const isBeforeEndDate = endDate ? visitDate <= new Date(endDate) : true;
        // Include the visit only if it satisfies both conditions.
        return isAfterStartDate && isBeforeEndDate;
    });
}

/**
 * Apply pagination to a combined list of visits.
 * @param {Array} combinedVisits - The combined array of visit records.
 * @param {number} currentPage - The current page number.
 * @param {number} pageSize - The number of rows per page.
 * @returns {Object} Paginated data containing visits and total pages.
 */
function applyPagination(combinedVisits, currentPage, pageSize) {
    const totalVisits = combinedVisits.length;
    const totalPages = Math.ceil(totalVisits / pageSize);
    const start = (currentPage - 1) * pageSize;
    const paginatedVisits = combinedVisits.slice(start, start + pageSize);

    return {visits: paginatedVisits, totalPages};
}

/**
 * Fetch, filter, and paginate visit records for all statuses with a date range.
 * @param {string} studyId - The patient study ID.
 * @param {string} token - The access token for authorization.
 * @param {number} currentPage - The current page number.
 * @param {number} pageSize - The number of rows per page.
 * @param {string|null} startDate - The start date for filtering visits.
 * @param {string|null} endDate - The end date for filtering visits.
 * @returns {Promise<Object>} Paginated data containing visits and total pages.
 */
async function fetchCombinedVisitRecordsWithPagination(studyId, token, currentPage, pageSize, startDate = null, endDate = null) {
    try {
        // Fetch completed visits (visit_status=1)
        const completedVisits = await fetchVisitRecordsByStatus(studyId, token, 1);
        // Fetch pending visits (visit_status=0)
        const pendingVisits = await fetchVisitRecordsByStatus(studyId, token, 0);
        // Combine both results
        let combinedVisits = [...completedVisits, ...pendingVisits];
        // Apply date range filter if provided
        combinedVisits = filterVisitsByDateRange(combinedVisits, startDate, endDate);
        // Apply pagination
        return applyPagination(combinedVisits, currentPage, pageSize);
    } catch (error) {
        console.error("Error fetching visit records:", error);
        throw error;
    }
}

export default {
    fetchCombinedVisitRecordsWithPagination
};
