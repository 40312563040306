export const PDF_TEXTS_ENGLISH = {
    UNKNOWN_STUDY: "UnknownStudy",
    FILENAME_PREFIX: "Questionnaires Report",
    DESCRIPTION: (questionnaireIdentity, visitTiming, submissionDate) =>
        `This is the ${questionnaireIdentity} questionnaire for timing ${visitTiming}. This questionnaire was created on ${submissionDate}.`,
    GLOBAL_SCORES_TITLE: "Global Scores",
    VALUE_NOT_AVAILABLE: "N/A",
    NO_SCORES_AVAILABLE: "No scores available.",
    DETAILED_SCORES_TITLE: "Detailed Scores",
    NO_DETAILED_SCORES_AVAILABLE: "No detailed scores available.",
    QUESTION_PREFIX: "Question",
    QUESTION_TEXT_PREFIX: "Q:",
    ANSWER_TEXT_PREFIX: "A:",
    ERROR_EXPORTING_PDF: "Error exporting PDF:"
};
export const PDF_TEXTS_ITALIAN = {
    UNKNOWN_STUDY: "StudioSconosciuto",
    FILENAME_PREFIX: "Rapporto Questionari",
    DESCRIPTION: (questionnaireIdentity, visitTiming, submissionDate) =>
        `Questo è il questionario ${questionnaireIdentity} per il tempo ${visitTiming}. Questo questionario è stato creato il ${submissionDate}.`,
    GLOBAL_SCORES_TITLE: "Punteggi Globali",
    VALUE_NOT_AVAILABLE: "N/D",
    NO_SCORES_AVAILABLE: "Nessun punteggio disponibile.",
    DETAILED_SCORES_TITLE: "Punteggi Dettagliati",
    NO_DETAILED_SCORES_AVAILABLE: "Nessun punteggio dettagliato disponibile.",
    QUESTION_PREFIX: "Domanda",
    QUESTION_TEXT_PREFIX: "D:",
    ANSWER_TEXT_PREFIX: "R:",
    ERROR_EXPORTING_PDF: "Errore nell'esportazione del PDF:"
};

export const QUESTIONNAIRE_TYPES = ['quality_of_lives', 'head_neck_cancers', 'cbibs', 'healths', 'care_received']

// Scores (EN)
export const GLOBAL_SCORE_NAMES_ENGLISH = {
    "quality_of_lives": [
        {name: "Apettite loss", code: "apettite_loss"},
        {name: "Cognitive functioning", code: "cognitive_functioning"},
        {name: "Constipation", code: "constipation"},
        {name: "Diarrhoea", code: "diarrhoea"},
        {name: "Dyspnoea", code: "dyspnoea"},
        {name: "Emotional functioning", code: "emotional_functioning"},
        {name: "Fatigue", code: "fatigue"},
        {name: "Financial difficulties", code: "financial_difficulties"},
        {name: "Global health status", code: "global_health_status"},
        {name: "Insomnia", code: "insomnia"},
        {name: "Nausea vomiting", code: "nausea_vomiting"},
        {name: "Pain", code: "pain"},
        {name: "Physical functioning", code: "physical_functioning"},
        {name: "Role functioning", code: "role_functioning"},
        {name: "Social functioning", code: "social_functioning"},
    ],
    "head_neck_cancers": [
        {name: "Pain in mouth", code: "pain_in_mouth"},
        {name: "Swallowing", code: "swallowing"},
        {name: "Problems with teeth", code: "problems_with_teeth"},
        {name: "Dry mouth and sticky saliva", code: "dry_mouth_sticky_saliva"},
        {name: "Problems with senses", code: "problems_with_senses"},
        {name: "Speech", code: "speech"},
        {name: "Body image", code: "body_image"},
        {name: "Social eating", code: "social_eating"},
        {name: "Sexuality", code: "sexuality"},
        {name: "Problems with shoulder", code: "problems_with_shoulder"},
        {name: "Skin problems", code: "skin_problems"},
        {name: "Problems opening mouth", code: "problems_opening_mouth"},
        {name: "Coughing", code: "coughing"},
        {name: "Social contact", code: "social_contact"},
        {name: "Swelling in the neck", code: "swelling_in_the_neck"},
        {name: "Weight loss", code: "weight_loss"},
        {name: "Problems with wound healing", code: "problems_wound_healing"},
        {name: "Neurological problems", code: "neurological_problems"},
    ],
    "care_received": [
        {name: "Care received", code: "answer"},
    ],
    "cbibs": [
        {name: "Coping and stress management", code: "casm"},
        {name: "Maintaining independence", code: "mi"},
        {name: "Managing affect", code: "me"},
        {name: "Participating in medical care", code: "pimc"},
    ],
    "healths": [
        {name: "5-digit code", code: "five_digit_code"},
        {name: "Severity index", code: "severity_index"},
        {name: "Health index", code: "health_index"},
        {name: "Your health today", code: "your_health_today"},
    ],
};
// Scores (IT)
export const GLOBAL_SCORE_NAMES_ITALIAN = {
    "quality_of_lives": [
        {name: "Perdita di appetito", code: "apettite_loss"},
        {name: "Funzionamento cognitivo", code: "cognitive_functioning"},
        {name: "Costipazione", code: "constipation"},
        {name: "Diarrea", code: "diarrhoea"},
        {name: "Dispnea", code: "dyspnoea"},
        {name: "Funzionamento emotivo", code: "emotional_functioning"},
        {name: "Affaticamento", code: "fatigue"},
        {name: "Difficoltà finanziarie", code: "financial_difficulties"},
        {name: "Stato di salute globale", code: "global_health_status"},
        {name: "Insonnia", code: "insomnia"},
        {name: "Nausea e vomito", code: "nausea_vomiting"},
        {name: "Dolore", code: "pain"},
        {name: "Funzionamento fisico", code: "physical_functioning"},
        {name: "Funzionamento nel ruolo", code: "role_functioning"},
        {name: "Funzionamento sociale", code: "social_functioning"},
    ],
    "head_neck_cancers": [
        {name: "Dolore alla bocca", code: "pain_in_mouth"},
        {name: "Difficoltà a deglutire", code: "swallowing"},
        {name: "Problemi ai denti", code: "problems_with_teeth"},
        {name: "Secchezza della bocca e saliva densa", code: "dry_mouth_sticky_saliva"},
        {name: "Problemi ai sensi", code: "problems_with_senses"},
        {name: "Difficoltà di linguaggio", code: "speech"},
        {name: "Immagine corporea", code: "body_image"},
        {name: "Mangiare in contesti sociali", code: "social_eating"},
        {name: "Sessualità", code: "sexuality"},
        {name: "Problemi alle spalle", code: "problems_with_shoulder"},
        {name: "Problemi della pelle", code: "skin_problems"},
        {name: "Difficoltà ad aprire la bocca", code: "problems_opening_mouth"},
        {name: "Tosse", code: "coughing"},
        {name: "Contatti sociali", code: "social_contact"},
        {name: "Gonfiore al collo", code: "swelling_in_the_neck"},
        {name: "Perdita di peso", code: "weight_loss"},
        {name: "Problemi di guarigione delle ferite", code: "problems_wound_healing"},
        {name: "Problemi neurologici", code: "neurological_problems"},
    ],
    "care_received": [
        {name: "Assistenza ricevuta", code: "answer"},
    ],
    "cbibs": [
        {name: "Gestione dello stress", code: "casm"},
        {name: "Mantenimento dell'indipendenza", code: "mi"},
        {name: "Gestione dell'affettività", code: "me"},
        {name: "Partecipazione alle cure mediche", code: "pimc"},
    ],
    "healths": [
        {name: "Codice a 5 cifre", code: "five_digit_code"},
        {name: "Indice di gravità", code: "severity_index"},
        {name: "Indice di salute", code: "health_index"},
        {name: "La tua salute oggi", code: "your_health_today"},
    ],
};

// Answers (EN)
export const QUESTIONNAIRE_ANSWERS_ENGLISH = {
    "quality_of_lives": {
        "-1": "-",
        "0": "N/A",
        "1": "Not at All",
        "2": "A Little",
        "3": "Quite a Bit",
        "4": "Very Much"
    },
    "head_neck_cancers": {
        "-1": "-",
        "0": "N/A",
        "1": "Not at All",
        "2": "A Little",
        "3": "Quite a Bit",
        "4": "Very Much"
    },
    "care_received": {
        "-1": "-",
        "0": "N/A",
        "1": "Absolutely Disagree",
        "2": "Moderately Disagree",
        "3": "Neither agree nor Disagree",
        "4": "Moderately agree",
        "5": "Absolutely agree"
    },
    "cbibs": {
        "-1": "-",
        "0": "N/A",
        "1": "1",  // Not at all confident
        "2": "2",
        "3": "3",
        "4": "4",
        "5": "5",  // Moderately confident
        "6": "6",
        "7": "7",
        "8": "8",
        "9": "9"   // Totally confident
    },
    "healths": {
        "74": { // "MOBILITY"
            "-1": "-",
            "0": "N/A",
            "1": "I have no problems in walking about",
            "2": "I have slight problems in walking about",
            "3": "I have moderate problems in walking about",
            "4": "I have severe problems in walking about",
            "5": "I am unable to walk about"
        },
        "75": { // "SELF-CARE"
            "-1": "-",
            "0": "N/A",
            "1": "I have no problem washing or dressing myself",
            "2": "I have slight problems washing or dressing myself",
            "3": "I have moderate problems washing or dressing myself",
            "4": "I have severe problems washing or dressing myself",
            "5": "I am unable to wash or dress myself"
        },
        "76": { // "USUAL ACTIVITIES (e.g. work, study, housework, family or leisure activities)"
            "-1": "-",
            "0": "N/A",
            "1": "I have no problem doing my usual activities",
            "2": "I have slight problems doing my usual activities",
            "3": "I have moderate problems doing my usual activities",
            "4": "I have severe problems doing my usual activities",
            "5": "I am unable to do my usual activities"
        },
        "77": { // "PAIN/DISCOMFORT"
            "-1": "-",
            "0": "N/A",
            "1": "I have no pain or discomfort",
            "2": "I have slight pain or discomfort",
            "3": "I have moderate pain or discomfort",
            "4": "I have severe pain or discomfort",
            "5": "I have extreme pain or discomfort"
        },
        "78": { // "ANXIETY/DEPRESSION"
            "-1": "-",
            "0": "N/A",
            "1": "I am not anxious or depressed",
            "2": "I am slightly anxious or depressed",
            "3": "I am moderately anxious or depressed",
            "4": "I am severely anxious or depressed",
            "5": "I am extremely anxious or depressed"
        }
        // 100: "Your health today" -> This scale is numbered from 0 to 100 (typed by the user)
    }
};
export const QUESTIONNAIRE_QUESTIONS_ENGLISH = {
    "quality_of_lives": {
        "1": "Do you have any trouble doing strenuous activities, like carrying a heavy shopping bag or a suitcase?",
        "2": "Do you have any trouble taking a long walk?",
        "3": "Do you have any trouble taking a short walk outside of the house?",
        "4": "Do you need to stay in bed or a chair during the day?",
        "5": "Do you need help with eating, dressing, washing yourself or using the toilet?",
        "6": "Were you limited in doing either your work or other daily activities?",
        "7": "Were you limited in pursuing your hobbies or other leisure time activities?",
        "8": "Were you short of breath?",
        "9": "Have you had pain?",
        "10": "Did you need to rest?",
        "11": "Have you had trouble sleeping?",
        "12": "Have you felt weak?",
        "13": "Have you lacked appetite?",
        "14": "Have you felt nauseated?",
        "15": "Have you vomited?",
        "16": "Have you been constipated?",
        "17": "Have you had diarrhea?",
        "18": "Were you tired?",
        "19": "Did pain interfere with your daily activities?",
        "20": "Have you had difficulty in concentrating on things, like reading a newspaper or watching television?",
        "21": "Did you feel tense?",
        "22": "Did you worry?",
        "23": "Did you feel irritable?",
        "24": "Did you feel depressed?",
        "25": "Have you had difficulty remembering things?",
        "26": "Has your physical condition or medical treatment interfered with your family life?",
        "27": "Has your physical condition or medical treatment interfered with your social activities?",
        "28": "Has your physical condition or medical treatment caused you financial difficulties?",
        "29": "How would you rate your overall health during the past week?",
        "30": "How would you rate your overall quality of life during the past week?"
    },
    "head_neck_cancers": {
        "31": "Have you had pain in your mouth?",
        "32": "Have you had pain in your jaw?",
        "33": "Have you had soreness in your mouth?",
        "34": "Have you had pain in your throat?",
        "35": "Have you had problems swallowing liquids?",
        "36": "Have you had problems swallowing pureed food?",
        "37": "Have you had problems swallowing solid food?",
        "38": "Have you choked when swallowing?",
        "39": "Have you had problems with your teeth?",
        "40": "Have you had problems because of losing some teeth?",
        "41": "Have you had problems opening your mouth wide?",
        "42": "Have you had a dry mouth?",
        "43": "Have you had sticky saliva?",
        "44": "Have you had problems with your sense of smell?",
        "45": "Have you had problems with your sense of taste?",
        "46": "Have you had problems with coughing?",
        "47": "Have you had problems with hoarseness?",
        "48": "Have you had problems with your appearance?",
        "49": "Have you felt less physically attractive as a result of your disease or treatment?",
        "50": "Have you felt dissatisfied with your body?",
        "51": "Have you had problems eating?",
        "52": "Have you had problems eating in front of your family?",
        "53": "Have you had problems eating in front of other people?",
        "54": "Have you had problems enjoying your meals?",
        "55": "Have you had problems talking to other people?",
        "56": "Have you had problems talking on the telephone?",
        "57": "Have you had problems talking in a noisy environment?",
        "58": "Have you had problems speaking clearly?",
        "59": "Have you had problems going out in public?",
        "60": "Have you felt less interest in sex?",
        "61": "Have you felt less sexual enjoyment?",
        "62": "Have you had problems raising your arm or moving it sideways?",
        "63": "Have you had pain in your shoulder?",
        "64": "Have you had swelling in your neck?",
        "65": "Have you had skin problems (e.g. itchy, dry)?",
        "66": "Have you had a rash?",
        "67": "Has your skin changed colour?",
        "68": "Have you worried that your weight is too low?",
        "69": "Have you worried about the results of examinations and tests?",
        "70": "Have you worried about your health in the future?",
        "71": "Have you had problems with wounds healing?",
        "72": "Have you had tingling or numbness in your hands or feet?",
        "73": "Have you had problems chewing?"
    },
    "healths": {
        "74": "MOBILITY",
        "75": "SELF-CARE",
        "76": "USUAL ACTIVITIES (e.g. work, study, housework, family or leisure activities)",
        "77": "PAIN/DISCOMFORT",
        "78": "ANXIETY/DEPRESSION",
        "100": "Your health today"
    },
    "cbibs": {
        "79": "Maintaining independence",
        "80": "Maintaining a positive attitude",
        "81": "Maintaining a sense of humor",
        "82": "Expressing negative feelings about cancer",
        "83": "Maintaining activities (work, home, hobbies, social)",
        "84": "Remaining relaxed throughout treatments and not allowing scary thoughts to upset me",
        "85": "Actively participating in treatment decisions",
        "86": "Asking physicians questions",
        "87": "Seeking consolation (support)",
        "88": "Sharing feelings of concern",
        "89": "Managing nausea and vomiting",
        "90": "Coping with physical changes"
    },
    "care_received": {
        "91": "Are you satisfied about the care you have received during the follow-up?"
    }
};

// Answers (IT)
export const QUESTIONNAIRE_ANSWERS_ITALIAN = {
    "quality_of_lives": {
        "-1": "-",
        "0": "N/A",
        "1": "No",
        "2": "Un po' ",
        "3": "Parecchio",
        "4": "Moltissimo"
    },
    "head_neck_cancers": {
        "-1": "-",
        "0": "N/A",
        "1": "No",
        "2": "Un po' ",
        "3": "Parecchio",
        "4": "Moltissimo"
    },
    "care_received": {
        "-1": "-",
        "0": "N/A",
        "1": "Assolutamente in disaccordo (non mi ritengo per nulla soddisfatto)",
        "2": "Abbastanza in disaccordo",
        "3": "Né d’accordo né in disaccordo",
        "4": "Abbastanza d’accordo",
        "5": "Assolutamente d’accordo (mi ritengo molto soddisfatto)"
    },
    "cbibs": {
        "-1": "-",
        "0": "N/A",
        "1": "1",  // Per niente fiducioso/a
        "2": "2",
        "3": "3",
        "4": "4",
        "5": "5",  // Moderatamente fiducioso/a
        "6": "6",
        "7": "7",
        "8": "8",
        "9": "9"  // Totalmente fiducioso/a
    },
    "healths": {
        "74": { // "MOBILITY"
            "-1": "-",
            "0": "N/A",
            "1": "Non ho difficoltà nel camminare",
            "2": "Ho lievi difficoltà nel camminare",
            "3": "Ho moderate difficoltà nel camminare",
            "4": "Ho gravi difficoltà nel camminare",
            "5": "Non sono in grado di camminare"
        },
        "75": { // "SELF-CARE"
            "-1": "-",
            "0": "N/A",
            "1": "Non ho difficoltà nel lavarmi o vestirmi",
            "2": "Ho lievi difficoltà nel lavarmi o vestirmi",
            "3": "Ho moderate difficoltà nel lavarmi o vestirmi",
            "4": "Ho gravi difficoltà nel lavarmi o vestirmi",
            "5": "Non sono in grado di lavarmi o vestirmi"
        },
        "76": { // "USUAL ACTIVITIES (e.g. work, study, housework, family or leisure activities)"
            "-1": "-",
            "0": "N/A",
            "1": "Non ho difficoltà nello svolgimento delle attività abituali",
            "2": "Ho lievi difficoltà nello svolgimento delle attività abituali",
            "3": "Ho moderate difficoltà nello svolgimento delle attività abituali",
            "4": "Ho gravi difficoltà nello svolgimento delle attività abituali",
            "5": "Non sono in grado di svolgere le mie attività abituali"
        },
        "77": { // "PAIN/DISCOMFORT"
            "-1": "-",
            "0": "N/A",
            "1": "Non provo alcun dolore o fastidio",
            "2": "Provo lieve dolore o fastidio",
            "3": "Provo moderato dolore o fastidio",
            "4": "Provo grave dolore o fastidio",
            "5": "Provo estremo dolore o fastidio"
        },
        "78": { // "ANXIETY/DEPRESSION"
            "-1": "-",
            "0": "N/A",
            "1": "Non sono ansioso/a o depresso/a",
            "2": "Sono lievemente ansioso/a o depresso/a",
            "3": "Sono moderatamente ansioso/a o depresso/a",
            "4": "Sono gravemente ansioso/a o depresso/a",
            "5": "Sono estremamente ansioso/a o depresso/a"
        }
        // 100: "Your health today" -> This scale is numbered from 0 to 100 (typed by the user)
    }
};
export const QUESTIONNAIRE_QUESTIONS_ITALIAN = {
    "quality_of_lives": {
        "1": "Ha difficoltà nel fare lavori faticosi, come sollevare una borsa della spesa pesante o una valigia?",
        "2": "Ha difficoltà nel fare una lunga passeggiata?",
        "3": "Ha difficoltà nel fare una breve passeggiata fuori casa?",
        "4": "Ha bisogno di stare a letto o su una sedia durante il giorno?",
        "5": "Ha bisogno di aiuto per mangiare, vestirsi, lavarsi o andare in bagno?",
        "6": "Ha avuto limitazioni nel fare il Suo lavoro o i lavori di casa?",
        "7": "Ha avuto limitazioni nel praticare i Suoi passatempi-hobby o altre attività di divertimento o svago?",
        "8": "Le è mancato il fiato?",
        "9": "Ha avuto dolore?",
        "10": "Ha avuto bisogno di riposo?",
        "11": "Ha avuto difficoltà a dormire?",
        "12": "Ha sentito debolezza?",
        "13": "Le è mancato l'appetito?",
        "14": "Ha avuto un senso di nausea?",
        "15": "Ha vomitato?",
        "16": "Ha avuto problemi di stitichezza?",
        "17": "Ha avuto problemi di diarrea?",
        "18": "Ha sentito stanchezza?",
        "19": "Il dolore ha interferito con le Sue attività quotidiane?",
        "20": "Ha avuto difficoltà a concentrarsi su cose come leggere un giornale o guardare la televisione?",
        "21": "Si è sentito(a) teso(a)?",
        "22": "Ha avuto preoccupazioni?",
        "23": "Ha avuto manifestazioni di irritabilità?",
        "24": "Ha avvertito uno stato di depressione?",
        "25": "Ha avuto difficoltà a ricordare le cose?",
        "26": "Le Sue condizioni fisiche o il Suo trattamento medico hanno interferito con la Sua vita familiare?",
        "27": "Le Sue condizioni fisiche o il Suo trattamento medico hanno interferito con le Sue attività sociali?",
        "28": "Le Sue condizioni fisiche o il Suo trattamento medico Le hanno causato difficoltà finanziarie?",
        "29": "Come valuterebbe in generale la Sua salute durante gli ultimi sette giorni?",
        "30": "Come valuterebbe in generale la Sua qualità di vita durante gli ultimi sette giorni?"
    },
    "head_neck_cancers": {
        "31": "Ha avuto dolore in bocca?",
        "32": "Ha avuto dolore alla mascella?",
        "33": "Ha avuto infiammazione in bocca?",
        "34": "Ha avuto dolore alla gola?",
        "35": "Ha avuto problemi a ingoiare liquidi?",
        "36": "Ha avuto problemi a ingoiare cibi passati?",
        "37": "Ha avuto problemi a ingoiare cibi solidi?",
        "38": "Ha avuto un senso di soffocamento quando ingoia?",
        "39": "Ha avuto problemi ai denti?",
        "40": "Ha avuto problemi a causa della perdita di qualche dente?",
        "41": "Ha avuto problemi ad aprire bene la bocca?",
        "42": "Ha avuto secchezza alla bocca?",
        "43": "Ha avuto la saliva impastata?",
        "44": "Ha avuto problemi con il senso dell'odorato?",
        "45": "Ha avuto problemi con il senso del gusto?",
        "46": "Ha avuto problemi a tossire?",
        "47": "Ha avuto problemi per la voce bassa o roca?",
        "48": "Ha avuto problemi a causa del suo aspetto fisico?",
        "49": "Si è sentito/a fisicamente meno attraente per effetto della malattia o della terapia?",
        "50": "Si è sentito/a insoddisfatto/a del Suo corpo?",
        "51": "Ha avuto problemi a mangiare?",
        "52": "Ha avuto problemi a mangiare in presenza della Sua famiglia?",
        "53": "Ha avuto problemi a mangiare in presenza di altre persone?",
        "54": "Ha avuto problemi nell'apprezzare i pasti?",
        "55": "Ha avuto problemi nel parlare con gli altri?",
        "56": "Ha avuto problemi nel parlare al telefono?",
        "57": "Ha avuto problemi nel conversare in un ambiente rumoroso?",
        "58": "Ha avuto problemi a parlare con chiarezza?",
        "59": "Ha avuto problemi ad andare tra la gente?",
        "60": "Ha provato minore interesse per il sesso?",
        "61": "Ha provato minore piacere nel sesso?",
        "62": "Ha avuto problemi a sollevare il braccio o a muoverlo lateralmente?",
        "63": "Ha avuto dolori alla spalla?",
        "64": "Ha avuto gonfiore al collo?",
        "65": "Ha avuto problemi di pelle (es. prurito, secchezza)?",
        "66": "Ha notato la comparsa di eruzioni cutanee?",
        "67": "Il colorito della Sua pelle è cambiato?",
        "68": "Si è sentito/a preoccupato/a che il Suo peso fosse troppo basso?",
        "69": "Si è sentito/a preoccupato/a per i risultati dei controlli clinici e degli esami diagnostici?",
        "70": "Si è sentito/a preoccupato/a per la Sua salute in futuro?",
        "71": "Ha avuto problemi con la cicatrizzazione delle ferite?",
        "72": "Ha provato una sensazione di formicolio e/o avuto minore sensibilità alle mani o ai piedi?",
        "73": "Ha avuto problemi a masticare?"
    },
    "healths": {
        "74": "CAPACITÀ DI MOVIMENTO",
        "75": "CURA DELLA PERSONA",
        "76": "ATTIVITÀ ABITUALI (per es. lavoro, studio, lavori domestici, attività familiari o di svago)",
        "77": "DOLORE O FASTIDIO",
        "78": "ANSIA O DEPRESSIONE",
        "100": "LA SUA SALUTE OGGI"
    },
    "cbibs": {
        "79": "Mantenere l’indipendenza",
        "80": "Mantenere un atteggiamento positivo",
        "81": "Mantenere il senso dell’umorismo",
        "82": "Esprimere sentimenti sul cancro",
        "83": "Mantenere le attività (lavoro, casa, interessi, relazioni sociali)",
        "84": "Cercare di rimanere rilassato durante il trattamento e non farmi turbare da pensieri spaventosi",
        "85": "Partecipare attivamente alle decisioni sui trattamenti",
        "86": "Fare domande ai medici",
        "87": "Cercare supporto sociale",
        "88": "Condividere le mie preoccupazioni con gli altri",
        "89": "Gestire la nausea e il vomito (a prescindere che io abbia avuto questi problemi in passato)",
        "90": "Affrontare i cambiamenti fisici"
    },
    "care_received": {
        "91": "È soddisfatto delle cure che ha ricevuto durante i controlli (follow-up)?"
    }
};
