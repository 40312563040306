import axios from "axios";

export const APIGW_URL = process.env.VUE_APP_API_URL_APIGW
const AUTH_URL = process.env.VUE_APP_AUTH_URL;

/**
 * Fetch access token for authentication.
 * @returns {Promise<string>} The access token.
 */
export async function fetchToken() {
    try {
        const params = new URLSearchParams();
        params.append("grant_type", process.env.VUE_APP_GRANT_TYPE);
        params.append("client_id", process.env.VUE_APP_CLIENT_ID);
        params.append("client_secret", process.env.VUE_APP_CLIENT_SECRET);

        const response = await axios.post(AUTH_URL, params, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        });

        return response.data.access_token;
    } catch (error) {
        console.error("Error fetching token:", error);
        throw error;
    }
}
