import axios from "axios";
import {APIGW_URL, fetchToken} from "./shared";
import {jsPDF} from "jspdf";
import {
    GLOBAL_SCORE_NAMES_ENGLISH,
    GLOBAL_SCORE_NAMES_ITALIAN,
    PDF_TEXTS_ENGLISH,
    PDF_TEXTS_ITALIAN,
    QUESTIONNAIRE_ANSWERS_ENGLISH,
    QUESTIONNAIRE_ANSWERS_ITALIAN,
    QUESTIONNAIRE_QUESTIONS_ENGLISH,
    QUESTIONNAIRE_QUESTIONS_ITALIAN,
    QUESTIONNAIRE_TYPES,
} from "./pdfConstants";
import {userstore} from "@/data/userstore";

/**
 * Fetch all completed questionnaires for a specific visit.
 * @param {string} studyId - The `study_id` of the logged-in user.
 * @param {string} idVisit - The `id_visit` of the visit record.
 * @returns {Promise<Array>} An array of completed questionnaire objects.
 */
async function fetchCompletedQuestionnaires(studyId, idVisit) {
    const apiUrl_questionnaires = `${APIGW_URL}/questionnaires?id_visit[eq]=${idVisit}&status=Completed&study_id[eq]=${studyId}`;

    try {
        const token = await fetchToken(); // Use fetchToken from shared.js
        const response = await axios.get(apiUrl_questionnaires, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching completed questionnaires:", error);
        throw error;
    }
}

/**
 * Fetch answers for a specific questionnaire.
 * @param {string} answerableId - The `answerable_id` of the questionnaire.
 * @returns {Promise<Array>} An array of answers related to the questionnaire.
 */
async function fetchQuestionnaireAnswers(answerableId) {
    const apiUrl_answers = `${APIGW_URL}/answers?answerable_id[eq]=${answerableId}`;
    try {
        const token = await fetchToken(); // Use fetchToken from shared.js

        const response = await axios.get(apiUrl_answers, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching answers for answerable_id=${answerableId}:`, error);
        throw error;
    }
}

/**
 * Fetch scores for a given score type.
 * @param {string} scoreType - The type of the score (e.g., 'quality_of_lives', 'head_neck_cancers', etc.)
 * @param {string} questionableId - The `id` corresponding to the score type.
 * @returns {Promise<Object|null>} The score data, or null if the score type is invalid.
 */
async function fetchScoresByType(scoreType, questionableId) {
    if (!QUESTIONNAIRE_TYPES.includes(scoreType)) {
        console.warn(`Invalid score type: ${scoreType}`);
        return null;
    }
    const apiUrl = `${APIGW_URL}/${scoreType}?id[eq]=${questionableId}`;
    try {
        const token = await fetchToken();

        const response = await axios.get(apiUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data[0]; // API returns an array
    } catch (error) {
        console.error(`Error fetching ${scoreType} scores:`, error);
        throw error;
    }
}

/**
 * Fetch all data needed for PDF export for a specific visit.
 * Combines questionnaires, their answers, and all score types into a single data structure.
 * @param {string} studyId - The `study_id` of the logged-in user.
 * @param {Object} visit - The `visit` record.
 * @returns {Promise<Object>} An object containing all data needed for PDF export.
 */
async function fetchVisitPdfData(studyId, visit) {
    try {
        // Fetch completed questionnaires
        const questionnaires = await fetchCompletedQuestionnaires(studyId, visit.id_visit);
        // Prepare an array to hold detailed data for each questionnaire
        const detailedQuestionnaires = [];
        // Iterate over each questionnaire to fetch its answers and scores
        for (const questionnaire of questionnaires) {
            const questionableId = questionnaire.questionable_id;
            // Fetch answers
            const answers = await fetchQuestionnaireAnswers(questionableId);
            // Fetch all score types
            const scoreTypes = QUESTIONNAIRE_TYPES;
            const scores = {};
            for (const scoreType of scoreTypes) {
                const scoreData = await fetchScoresByType(scoreType, questionableId);
                if (scoreData) {
                    scores[scoreType] = scoreData;
                }
            }
            // Combine all data into a single object
            const detailedData = {
                questionnaire,
                answers,
                scores,
            };
            detailedQuestionnaires.push(detailedData);
        }
        // Return the combined data as an object
        const pdfData = {
            studyId,
            visit,
            detailedQuestionnaires,
        };
        return pdfData;
    } catch (error) {
        console.error("Error fetching visit PDF data:", error);
        throw error;
    }
}

/**
 * Export a PDF for the given visit.
 * The filename will follow the pattern: "Questionnaires Report_studyId_visitDate" (similar in Italian).
 * @param {Object} visit - The visit object for which the PDF will be exported.
 */
async function exportPDF(visit) {
    try {
        const lang = userstore.state.lang || "en"; // Get current language
        // Select constants based on language
        const PDF_TEXTS_TO_USE = lang === "it" ? PDF_TEXTS_ITALIAN : PDF_TEXTS_ENGLISH;
        const GLOBAL_SCORE_NAMES_TO_USE = lang === "it" ? GLOBAL_SCORE_NAMES_ITALIAN : GLOBAL_SCORE_NAMES_ENGLISH;
        const QUESTIONNAIRE_QUESTIONS = lang === "it" ? QUESTIONNAIRE_QUESTIONS_ITALIAN : QUESTIONNAIRE_QUESTIONS_ENGLISH;
        const QUESTIONNAIRE_ANSWERS = lang === "it" ? QUESTIONNAIRE_ANSWERS_ITALIAN : QUESTIONNAIRE_ANSWERS_ENGLISH;
        // Extract studyId and visitDate
        const studyId = visit.study_id || PDF_TEXTS_TO_USE.UNKNOWN_STUDY;
        const visitDate = new Date(visit.date).toISOString().split("T")[0]; // Format as YYYY-MM-DD
        // Create the filename
        const filename = `${PDF_TEXTS_TO_USE.FILENAME_PREFIX}_${studyId}_${visitDate}.pdf`;
        // Fetch data for the PDF using the fetchVisitPdfData service
        const pdfData = await fetchVisitPdfData(studyId, visit);
        // Create a new PDF document
        const doc = new jsPDF();
        // Loop through each questionnaire and add its section
        pdfData.detailedQuestionnaires.forEach((entry, index) => {
            if (index > 0) doc.addPage(); // Add a new page for each questionnaire after the first
            // Set the title for each questionnaire section
            const title = entry.questionnaire.questionnaire_identity;
            doc.setFontSize(16);
            doc.text(title, 10, 20); // Position the title at the top of the page
            // Add description under the title
            const questionnaireIdentity = entry.questionnaire.questionnaire_identity;
            const visitTiming = visit.visit_timing;
            const submissionDate = new Date(entry.questionnaire.submission_date).toLocaleDateString('en-GB'); // Format to dd-mm-yyyy
            // Use the DESCRIPTION function from PDF_TEXTS_TO_USE
            const description = PDF_TEXTS_TO_USE.DESCRIPTION(questionnaireIdentity, visitTiming, submissionDate);
            doc.setFontSize(12);
            doc.text(description, 10, 30); // Position the description below the title
            // Add 'Global Scores' subsection
            doc.setFontSize(14);
            doc.text(PDF_TEXTS_TO_USE.GLOBAL_SCORES_TITLE, 10, 45); // Position the 'Global Scores' title below the description
            // Determine which score type to use based on 'questionable_type'
            const scoreType = entry.questionnaire.questionable_type;
            const scores = entry.scores[scoreType];
            let yPosition = 55;
            if (scores) {
                // Get the array of score names and codes
                const scoreFields = GLOBAL_SCORE_NAMES_TO_USE[scoreType];
                // Loop through each score field and add to PDF
                scoreFields.forEach((field) => {
                    const displayName = field.name;
                    const code = field.code;
                    const value = scores[code] !== undefined ? scores[code] : PDF_TEXTS_TO_USE.VALUE_NOT_AVAILABLE;
                    // Add the name and value to the PDF
                    doc.setFontSize(12);
                    doc.text(`${displayName}: ${value}`, 10, yPosition);
                    yPosition += 10; // Move down for next line
                });
            } else {
                // If no scores found for the type
                doc.setFontSize(12);
                doc.text(PDF_TEXTS_TO_USE.NO_SCORES_AVAILABLE, 10, yPosition);
                yPosition += 10;
            }
            // Add 'Detailed Scores' subsection
            yPosition += 10; // Add some space after Global Scores
            doc.setFontSize(14);
            doc.text(PDF_TEXTS_TO_USE.DETAILED_SCORES_TITLE, 10, yPosition);
            yPosition += 10;
            // Filter answers based on 'answerable_type' matching 'questionable_type'
            const filteredAnswers = entry.answers.filter(answer => answer.answerable_type === scoreType);
            // Get the question and answer mappings
            const questionMappings = QUESTIONNAIRE_QUESTIONS[scoreType];
            const answerMappings = QUESTIONNAIRE_ANSWERS[scoreType];
            // Ensure that mappings exist
            if (!questionMappings || !answerMappings) {
                doc.setFontSize(12);
                doc.text(PDF_TEXTS_TO_USE.NO_DETAILED_SCORES_AVAILABLE, 10, yPosition);
                return;
            }
            // Loop through each filtered answer and add question and answer to the PDF
            filteredAnswers.forEach(answer => {
                const questionId = String(answer.question_id);
                const answerValue = String(answer.answer_integer);
                const questionText = questionMappings[questionId] || `${PDF_TEXTS_TO_USE.QUESTION_PREFIX} ${questionId}`;
                let answerText;
                if (scoreType === 'healths') {
                    // For 'healths', answers are nested under question_id
                    const answerOptions = answerMappings[questionId];
                    answerText = answerOptions ? answerOptions[answerValue] || `${answerValue}` : `${answerValue}`;
                } else {
                    answerText = answerMappings[answerValue] || `${answerValue}`;
                }
                // Add question
                doc.setFontSize(12);
                doc.text(`${PDF_TEXTS_TO_USE.QUESTION_TEXT_PREFIX} ${questionText}`, 10, yPosition);
                yPosition += 7;
                // Add answer
                doc.setFontSize(12);
                doc.text(`${PDF_TEXTS_TO_USE.ANSWER_TEXT_PREFIX} ${answerText}`, 10, yPosition);
                yPosition += 10;
                // Check if yPosition exceeds page height, and add new page if necessary
                if (yPosition > doc.internal.pageSize.height - 20) {
                    doc.addPage();
                    yPosition = 20;
                }
            });
        });
        // Save the PDF file with the filename
        doc.save(filename);
    } catch (error) {
        console.error(PDF_TEXTS_ENGLISH.ERROR_EXPORTING_PDF, error);
        throw error;
    }
}


export default {
    exportPDF
};
