import Vue from 'vue'
import App from './App.vue'
//import Appnonav from './Appnonav.vue'
import Login from './components/Login'
import VueRouter from 'vue-router'
import vueResource from 'vue-resource'
import Home from './components/Home'
import CBIB from './components/CBIB'
//import QLQC30 from './components/qlq-c30.vue'
import QLQC30VERTICAL from './components/qlq-c30-vertical.vue'
import CARE from './components/care.vue'
import HEALTH from './components/health.vue'
import HEADNECK from './components/HEADNECK.vue'
import ListQuestionnaires from './components/list-questionnaires.vue'
import Select from './components/select'
import VisitHistory from './components/VisitHistory.vue'
//import Register from './components/Register'
import Forgotpassword from './components/Forgotpassword'
import Report from './components/Report'
//import databaseexport from './components/database-export'
//import Stepper from 'vuejs-stepper'
import './components/style.css'
import VueFormulate from '@braid/vue-formulate'
import { MdButton, MdContent, MdTabs, MdSteppers, MdSnackbar, MdIcon, MdField, MdDialogConfirm, MdRadio } from 'vue-material/dist/components'
import VueMaterial from 'vue-material'
import Vuex from 'vuex'
import { userstore } from './data/userstore.js';
import { SquareSpinner, BounceSpinner, CubeSpinner, FoldSpinner, PulseSpinner, TileSpinner } from 'vue-spinners'
import vSelect from 'vue-select'



import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'


Vue.use(VueFormulate)
Vue.use(vueResource)
Vue.use(MdDialogConfirm)
Vue.use(VueRouter)
Vue.use(MdButton)
Vue.use(MdContent)
Vue.use(MdTabs)
Vue.use(MdSteppers)
Vue.use(MdSnackbar)
Vue.use(MdIcon)
Vue.use(MdRadio)
Vue.use(MdField)
Vue.use(Vuex)
Vue.use(VueMaterial)

Vue.component('v-select', vSelect)
Vue.component('square', SquareSpinner)
Vue.component('bounce', BounceSpinner)
Vue.component('cube', CubeSpinner)
Vue.component('fold', FoldSpinner)
Vue.component('pulse', PulseSpinner)
Vue.component('tile', TileSpinner)




//Vue.use(Stepper)

Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/home', component: Home, name: 'Home' },
    { path: '/login', component: Login, name: 'Login' },
    //  {path:'/register',component:Register,name:'Register'},
    { path: '/report', component: Report, name: 'Report' },
    { path: '/cancer-behavior-inventory', component: CBIB, name: 'CBI' },
    //  {path:'/qlq-c30',component:QLQC30,name:'QLQC30'},
    { path: '/qlq-c30', component: QLQC30VERTICAL, name: 'QLQC30VERTICAL' },
    { path: '/care-received', component: CARE, name: 'CARE' },
    { path: '/health', component: HEALTH, name: 'HEALTH', router: router },
    { path: '/head-and-neck-cancer', component: HEADNECK, name: 'HEADNECK' },
    { path: '/select-questionnaire', component: Select, name: 'Select' },
    { path: '/forgot-password', component: Forgotpassword, name: 'Forgotpassword' },
    { path: '/list-questionnaires', component: ListQuestionnaires, name: 'ListQuestionnaires' },
    { path: '/visit-history', component: VisitHistory, name: 'VisitHistory' },
    // {path:'/export',component:databaseexport,name:'databaseexport'},
  ]
})



router.beforeEach((to, from, next) => {
  if (to.path != '/list-questionnaires') {
    if (to.path != '/login' && to.path != '/home' && to.path != '/register' && to.path != '/forgot-password' && userstore.state.id == null) {
      next({ name: 'Login', path: '/login' })
    }
    else {
      next();
      console.log('current route', router.currentRoute);
    }
  }
  else {
    next();
    console.log('current route clinician', router.currentRoute);
  }
})

new Vue({
  router,
  data: {

  },
  updated: function () {

  },
  created: function () {

  },
  // render: h => router.currentRoute.name=="Login"||router.currentRoute.name=="Forgotpassword" ? h(Appnonav) : h(App)
  render: h => h(App)
}).$mount('#app')


